import "./cdcweb.css";
import fondocdc from "./images/fondoCDC3.jpg";
import logocdc from "./images/LOGO CLINICA.png";

const CdcWeb = () => {
  return (
    <div className="cdc-web">
      <div className="container">
        <div className="overlap-group">
          <img className="fondocdc" src={fondocdc} alt="Fondocdc" />
          <img className="LOGO-CLINICA" src={logocdc} alt="Logo CLINICA" />
          <div className="text-wrapper">Clínica Dr. Chamorro</div>
          <div className="div">Teléfonos:</div>
          <div className="text-wrapper-2">María: 664 475 601</div>
          <div className="text-wrapper-3">Laura: 664 338 174</div>
        </div>
      </div>
    </div>
  );
};

export default CdcWeb;